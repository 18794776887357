import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../../app/axiosInstance";
import { handleApiError } from "../../common/utils/ErrorHandler";
import { prepareHeaders } from "../../auth/slice/AuthSlice";
import { PER_PAGE_SKU } from "../../../constants/pagination";
import { CURRENCY_USD } from "../../../constants/currency";
import { ADDITIONAL_INFO, ADDITIONAL_INFO_SUBMITTED, CANCELED, FINALIZED, IN_PROGRESS, IN_REVIEW, NOT_RESPONDED } from "../../../constants/sku";


const customerUrl = `/api/customer`;
const customerSkuUrl = `${customerUrl}/sku`;

export const fetchPriceList = createAsyncThunk(
  'priceLists/fetchPriceList',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const headers = prepareHeaders(state);
      const response = await axiosInstance.get('api/price-list', {
        params: { "filter_currency": CURRENCY_USD },
        headers: headers
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchDiscountCoupon = createAsyncThunk(
  'coupons/fetchDiscountCoupon',
  async (coupon, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const headers = prepareHeaders(state);
      const response = await axiosInstance.get('api/customer/is-valid-discount-coupon', {
        headers: headers,
        params: coupon
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const saveCustomerSku = createAsyncThunk(
  'skus/saveCustomerSku',
  async (newCustomerSku, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(`${customerSkuUrl}/store`, newCustomerSku, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const storeReferenceName = createAsyncThunk(
  'skus/storeReferenceName',
  async (newData, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(`${customerSkuUrl}/store-reference-name`, newData, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

const fetchSkuData = async ({ page = 1, perPage = PER_PAGE_SKU, filter_status = '', sort_by = '', sort_direction = '', start_date = '', end_date = '' }, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.get(`${customerSkuUrl}/listing_sku`, {
      params: { page, per_page: perPage, filter_status, sort_by, sort_direction, start_date, end_date },
      headers: prepareHeaders(state)
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
}

export const fetchSkus = createAsyncThunk('skus/fetchSkus', (args, { getState, rejectWithValue, dispatch }) => fetchSkuData(args, { getState, rejectWithValue, dispatch }));

export const fetchInProgressSkus = createAsyncThunk('skus/fetchInProgressSkus', (args, { getState, rejectWithValue, dispatch }) => fetchSkuData({ filter_status: IN_PROGRESS + ',' + IN_REVIEW + ',' + ADDITIONAL_INFO + ',' + ADDITIONAL_INFO_SUBMITTED + ',' + NOT_RESPONDED, ...args }, { getState, rejectWithValue, dispatch }));

export const fetchCompletedSkus = createAsyncThunk('skus/fetchCompletedSkus', (args, { getState, rejectWithValue, dispatch }) => fetchSkuData({ filter_status: FINALIZED + ',' + CANCELED, ...args }, { getState, rejectWithValue, dispatch }));

export const createPaymentIntent = createAsyncThunk(
  'skus/createPaymentIntent',
  async (newData, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(`${customerUrl}/create-payment-intent`, newData, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchSkuCount = createAsyncThunk(
  'skus/fetchSkuCount',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${customerSkuUrl}/count`, {
        headers: prepareHeaders(state, true)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const requestComplianceCheck = createAsyncThunk(
  'skus/requestComplianceCheck',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(`${customerSkuUrl}/check-request`, data, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

const SkuSlice = createSlice({
  name: "sku",
  initialState: {
    skus: [],
    progressSkus: [],
    completedSkus: [],
    sku: null,
    skuCountData: null,
    customerSku: null,
    discount: null,
    paymentIntent: null,
    priceList: [],
    loading: false,
    isProgressloading: false,
    isCompletedloading: false,
    isNameSaving: false,
    priceListLoading: false,
    isRequesting: false,
    pageCount: 0,
    currentPage: 0,
    inProgressPageCount: 0,
    availableSkusCount: 0,
    totalSkusCount: 0,
    inProgressSkusCount: 0,
    completedSkusCount: 0,
    filter: '',
    filter_status: '',
    sort_by: '',
    sort_direction: '',
    perPage: PER_PAGE_SKU,
    error: null,
    success: null,
    validationError: []
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
      state.currentPage = 0;
    },
    setFilterStatus: (state, action) => {
      state.filter_status = action.payload;
      state.currentPage = 0;
    },
    setSortData: (state, action) => {
      state.sort_by = action.payload?.key;
      state.sort_direction = action.payload?.order;
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
      state.currentPage = 0;
    },
    setCustomerSku: (state, action) => {
      state.customerSku = action.payload;
    },
    clearCustomerSku: (state) => {
      state.customerSku = null;
    },
    clearDiscount: (state) => {
      state.discount = null;
    },
    clearError: (state) => {
      state.error = '';
    },
    clearPaymentIntent: (state) => {
      state.paymentIntent = null;
    },
    clearSku: (state) => {
      state.sku = null;
    },
    clearSuccess: (state) => {
      state.success = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // fetch skus
      .addCase(fetchSkus.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchSkus.fulfilled, (state, action) => {
        state.loading = false;
        const payload = action.payload?.payload;
        const combinedSkus = payload?.combined_skus;
        state.skus = combinedSkus?.data;
        state.availableSkusCount = payload?.available_skus ?? 0;
        state.totalSkusCount = payload?.total_skus ?? 0;
        state.completedSkusCount = payload?.completed_skus ?? 0;
        state.inProgressSkusCount = payload?.in_progress_skus ?? 0;
        state.pageCount = combinedSkus?.last_page ?? 0;
        state.currentPage = combinedSkus?.current_page ? combinedSkus.current_page - 1 : 0;
      })
      .addCase(fetchSkus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // fetch in progress skus
      .addCase(fetchInProgressSkus.pending, (state) => {
        state.isProgressloading = true;
        state.progressError = '';
      })
      .addCase(fetchInProgressSkus.fulfilled, (state, action) => {
        state.isProgressloading = false;
        const payload = action.payload?.payload;
        const combinedSkus = payload?.combined_skus;
        state.inProgressSkus = combinedSkus?.data;
        state.availableSkusCount = payload?.available_skus ?? 0;
        state.totalSkusCount = payload?.total_skus ?? 0;
        state.completedSkusCount = payload?.completed_skus ?? 0;
        state.inProgressSkusCount = payload?.in_progress_skus ?? 0;
        state.inProgressPageCount = combinedSkus?.last_page ?? 0;
      })
      .addCase(fetchInProgressSkus.rejected, (state, action) => {
        state.isProgressloading = false;
        state.progressError = action.payload;
      })
      // fetch completed skus
      .addCase(fetchCompletedSkus.pending, (state) => {
        state.isCompletedloading = true;
        state.completedError = '';
      })
      .addCase(fetchCompletedSkus.fulfilled, (state, action) => {
        state.isCompletedloading = false;
        const payload = action.payload?.payload;
        const combinedSkus = payload?.combined_skus;
        state.completedSkus = combinedSkus?.data;
        state.availableSkusCount = payload?.available_skus ?? 0;
        state.totalSkusCount = payload?.total_skus ?? 0;
        state.completedSkusCount = payload?.completed_skus ?? 0;
        state.completedSkusCount = payload?.in_progress_skus ?? 0;
        state.completedPageCount = combinedSkus?.last_page ?? 0;
      })
      .addCase(fetchCompletedSkus.rejected, (state, action) => {
        state.isCompletedloading = false;
        state.completedError = action.payload;
      })
      // fetch pricelist
      .addCase(fetchPriceList.pending, (state) => {
        state.priceListLoading = true;
        state.error = '';
      })
      .addCase(fetchPriceList.fulfilled, (state, action) => {
        state.priceListLoading = false;
        state.priceList = action.payload.payload;
      })
      .addCase(fetchPriceList.rejected, (state, action) => {
        state.priceListLoading = false;
        state.error = action.payload;
      })
      // fetch discount coupon
      .addCase(fetchDiscountCoupon.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchDiscountCoupon.fulfilled, (state, action) => {
        state.loading = false;
        state.discount = action.payload.payload;
      })
      .addCase(fetchDiscountCoupon.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // fetch sku count
      .addCase(fetchSkuCount.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchSkuCount.fulfilled, (state, action) => {
        state.loading = false;
        state.skuCountData = action.payload.payload;
      })
      .addCase(fetchSkuCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // request compliance check
      .addCase(requestComplianceCheck.pending, (state) => {
        state.isRequesting = true;
        state.validationError = [];
      })
      .addCase(requestComplianceCheck.fulfilled, (state, action) => {
        state.isRequesting = false;
        state.validationError = [];
        state.skus = [action.payload.payload, ...state.skus];
        let availableSkusCount = state.availableSkusCount - action.payload?.payload?.used_skus;
        state.availableSkusCount = availableSkusCount;
      })
      .addCase(requestComplianceCheck.rejected, (state, action) => {
        state.isRequesting = false;
        if (typeof action.payload !== 'string') {
          state.validationError = action.payload;
        }
      })
      // create payment intent
      .addCase(createPaymentIntent.pending, (state, action) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(createPaymentIntent.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentIntent = action.payload.payload;
        state.error = '';
      })
      .addCase(createPaymentIntent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // save customer sku
      .addCase(saveCustomerSku.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(saveCustomerSku.fulfilled, (state, action) => {
        state.loading = false;
        state.sku = action.payload.payload;
      })
      .addCase(saveCustomerSku.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // save sku reference name
      .addCase(storeReferenceName.pending, (state) => {
        state.isNameSaving = true;
        state.error = '';
      })
      .addCase(storeReferenceName.fulfilled, (state, action) => {
        state.isNameSaving = false;
        let data = action.payload.payload;
        const index = state.skus.findIndex(sku => sku.id === data.id);
        if (index !== -1) {
          state.skus[index] = data;
        }
        state.sku = data;
      })
      .addCase(storeReferenceName.rejected, (state, action) => {
        state.isNameSaving = false;
        state.error = action.payload;
      });
  },
});

export const { setCustomerSku, clearPaymentIntent, clearSku, setFilter, setFilterStatus, setSortData, setPage, setPerPage, clearCustomerSku, clearDiscount, clearError, clearSuccess, clearValidationError } = SkuSlice.actions;
export default SkuSlice.reducer;